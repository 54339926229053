import { mapHelper } from 'common/utils'

// 平台名称
const topMenuId = [
  {
    text: '服务一体化',
    value: 1
  },
  {
    text: '物联网一体化',
    value: 2
  },
  {
    text: '数据一体化',
    value: 3
  }
]
// 状态
const status = [
  {
    text: '处理中',
    value: 0
  },
  {
    text: '生成中',
    value: 1
  },
  {
    text: '生成完毕',
    value: 2
  },
  {
    text: '导出失败',
    value: 3
  }
]

const { map: topMenuIdMap, setOps: topMenuIdOps } = mapHelper.setMap(topMenuId)
const { map: statusMap, setOps: statusOps } = mapHelper.setMap(status)

export { topMenuIdMap, topMenuIdOps, statusMap, statusOps }
