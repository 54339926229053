// 获取列表
const getListURL = `${API_CONFIG.butlerBaseURL}export/log`
// 获取角色
const getRoleTypeListURL = `${API_CONFIG.baseURL}serverUserAction!getRoleType.action`
// 获取菜单
const getPlatformMenuURL = `${API_CONFIG.controlBaseURL}sysRole/menuResource`
// 角色归属
const getOrgURL = `${API_CONFIG.baseURL}serverRoleAction!getOrganize.action`

export { getListURL, getRoleTypeListURL, getPlatformMenuURL, getOrgURL }
