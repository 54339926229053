<!--
 * @Description: 导出数据列表（数据中心和我的导出共用的页面，需要这样处理才不会有问题）
 * @Author: 小广
 * @Date: 2020-04-15 14:36:54
 * @LastEditors: 小广
 * @LastEditTime: 2020-05-29 17:58:29
 -->
<template>
  <div class="DataExportItem-wrapper">
    <list ref="list"
          v-if="canInit && listVisible"
          :searchUrl="searchUrl"
          :searchParams.sync="searchParams"
          :headers="headers">
      <template #searchSlot>
        <v-select label="平台名称"
                  v-model="searchParams.topMenuId"
                  :options="topMenuIdOps"
                  @change="topMenuChange"></v-select>
        <v-select label="中心名称"
                  v-model="searchParams.subCenterMenuId"
                  :options="subCenterMenuIdOps"
                  :disabled="!searchParams.topMenuId"
                  @change="subCenterMenuIdChange"></v-select>
        <v-select label="模块名称"
                  v-model="searchParams.platformMenuId"
                  :options="platformMenuIdOps"
                  :disabled="!searchParams.subCenterMenuId"
                  @change="platformMenuIdChange"></v-select>
        <v-select label="菜单名称"
                  v-model="searchParams.childMenuId"
                  :disabled="!searchParams.platformMenuId"
                  :options="childMenuIdOps"></v-select>
        <v-select label="状态"
                  v-model="searchParams.status"
                  :options="statusOps"></v-select>
        <v-select v-if="type !== 1 && hasDownDateView" label="角色类型"
                  v-model="searchParams.userType"
                  :options="userTypeOps"
                  @change="userTupeChange"></v-select>
        <v-select2 v-if="type !== 1" label="角色归属"
                   ref="orgId"
                   v-model="searchParams.orgId"
                   v-bind="orgIdParams"></v-select2>
        <v-datepicker label="创建时间段"
                      type="rangedatetimer"
                      :startTime.sync="searchParams.startTime"
                      :endTime.sync="searchParams.endTime"
                      :maxDate="maxDate"></v-datepicker>
      </template>
      <template #operateSlot="scope">
        <v-button text="下载"
                  type="text"
                  class="downLoadBtn"
                  v-if="scope.row.filePath"
                  permission="download"
                  @click="downloadFile(scope.row)"></v-button>
      </template>
    </list>
  </div>
</template>

<script>
import { getListURL, getRoleTypeListURL, getPlatformMenuURL, getOrgURL } from './api'
import { statusOps } from './map'

export default {
  name: 'exportcentre',
  data () {
    return {
      canInit: false,
      maxDate: new Date(),
      searchUrl: getListURL,
      topMenuIdOps: [{ text: '全部', value: undefined }],
      statusOps: statusOps(1),
      platformMenuList: [],
      moduleMenuList: [],
      subCenterMenuIdOps: [
        {
          text: '全部',
          value: undefined
        }
      ],
      platformMenuIdOps: [
        {
          text: '全部',
          value: undefined
        }
      ],
      childMenuIdOps: [
        {
          text: '全部',
          value: undefined
        }
      ],
      userTypeOps: [],
      orgIdParams: {
        searchUrl: getOrgURL,
        request: {
          text: 'orgName_',
          value: 'orgId_'
        },
        response: {
          text: 'name',
          value: 'id'
        },
        subjoin: {}
      },
      searchParams: {
        topMenuId: undefined,
        subCenterMenuId: undefined, // 中心名称
        platformMenuId: undefined, // 模块名称
        moduleMenuId: undefined,
        childMenuId: undefined,
        status: undefined,
        userType: undefined,
        orgId: undefined,
        startTime: undefined,
        endTime: '',
        myExport: this.type
      },
      headers: [
        {
          prop: 'intime',
          label: '日期'
        },
        {
          prop: 'name',
          label: '报表名称'
        },
        {
          prop: 'requestParams',
          label: '搜索字段'
        },
        {
          prop: 'fileSize',
          label: '大小',
          formatter: (row, prop) => {
            let fileSize = ''
            if (row.status === 2 && row.fileSize === 0) {
              fileSize = 0.01 + 'MB'
            } else {
              fileSize = row.fileSize + 'MB'
            }
            return fileSize
          }
        },
        {
          prop: 'status',
          label: '状态',
          formatter: (row, prop) => {
            let _status = {
              0: '处理中',
              1: '生成中',
              2: '生成完毕',
              3: '导出失败'
            }
            let status = ''
            if (row.status === 1) {
              status = row.percent + '%'
            } else {
              status = _status[row.status]
            }
            return status
          }
        },
        {
          prop: 'inuserName',
          label: '操作人'
        },
        {
          prop: 'orgName',
          label: '角色归属'
        }
      ],
      listVisible: false, // 组织中心等待查询条件，查询完毕后显示列表
      // 所有的菜单数据
      allMenuList: []
    }
  },

  props: {
    type: {
      type: Number,
      default: 0
    }
  },
  computed: {
    isOrgnizeCenter () {
      // 是否是组织中心
      return this.$route.query.cid - 0 === 80
    },
    hasDownDateView () {
      return this.$store.state.permission.downDataView
    }
  },
  created () {
    if (this.hasDownDateView) {
      this.getRoleType()
    } else {
      this.canInit = true
    }
  },
  async mounted () {
    const routeName = this.$route.name
    const searchParams = this.$store.state.searchStore.listSearch[routeName] || {}
    this.searchParams = { ...this.searchParams, ...searchParams }
    if (searchParams.userType) {
      this.orgIdParams.subjoin.roleType = this.searchParams.userType
    }
    if (!this.searchParams.userType) {
      this.searchParams.userType = this.$store.state.userInfo.userType
      this.orgIdParams.subjoin.roleType = this.searchParams.userType
    }
    if (this.isOrgnizeCenter) {
      // 组织中心平台名称选中服务一体化
      this.searchParams.topMenuId = 1
    } else {
      // 非组织中心的导出直接显示
      this.listVisible = true
    }
    await this.requestForMenuData()
    this.getTopMenuData()
    if (this.searchParams.topMenuId) {
      this.getSubCenterMenuData()
      this.getModuleMenuData()
      this.getViewMenuData()
    }
  },
  methods: {
    platformMenuIdChange (val, item) {
      this.searchParams.childMenuId = undefined
      this.getViewMenuData()
    },
    subCenterMenuIdChange (val, item) {
      this.searchParams.platformMenuId = undefined
      this.searchParams.childMenuId = undefined
      this.getModuleMenuData()
    },
    topMenuChange (val, item) {
      this.searchParams.subCenterMenuId = undefined
      this.searchParams.platformMenuId = undefined
      this.searchParams.childMenuId = undefined
      this.getSubCenterMenuData()
    },
    userTupeChange (val, item) {
      this.orgIdParams.subjoin.roleType = val
    },
    getRoleType () {
      this.$axios.get(getRoleTypeListURL, {}).then(res => {
        if (res.status === '100') {
          let data = res.data
          let list = []
          data.forEach((v, i, a) => {
            list.push({
              text: v.name,
              value: v.id
            })
          })
          this.userTypeOps = list
          if (list.length && !this.searchParams.userType) {
            this.searchParams.userType = list[0].value
            this.orgIdParams.subjoin.roleType = list[0].value
          }
        }
        this.canInit = true
      })
    },
    downloadFile (row) {
      let href = row.filePath
      window.location.href = href
    },

    // 获取数据
    async requestForMenuData () {
      let params = {}
      const { data, status } = await this.$axios.get(getPlatformMenuURL, { params })
      let list
      if (status === 100) {
        list = data || []
      }
      this.allMenuList = list || []
    },

    // 获取平台名称ops
    getTopMenuData () {
      let topMenuOps = [{
        text: '全部',
        value: undefined
      }]
      this.allMenuList && this.allMenuList.forEach(topMenu => {
        topMenuOps.push({
          text: topMenu.name,
          value: topMenu.id
        })
      })
      this.topMenuIdOps = topMenuOps
    },

    // 获取中心名称ops
    getSubCenterMenuData () {
      let topMenuId = this.searchParams.topMenuId
      let subCenterMenuOps = [{
        text: '全部',
        value: undefined
      }]
      if (topMenuId) {
        this.allMenuList && this.allMenuList && this.allMenuList.forEach(topMenu => {
          if (topMenu.id === topMenuId) {
            if (topMenu.childMenus) {
              // let tempCenterMenuList = topMenu.childMenus.filter(obj => obj.menuType !== 3)
              topMenu.childMenus && topMenu.childMenus.forEach(centerMenu => {
                subCenterMenuOps.push({
                  text: centerMenu.name,
                  value: centerMenu.id
                })
              })
            }
          }
        })
      }
      this.subCenterMenuIdOps = subCenterMenuOps
    },

    // 获取模块名称ops
    getModuleMenuData () {
      let topMenuId = this.searchParams.topMenuId
      let subCenterMenuId = this.searchParams.subCenterMenuId
      let moduleMenuOps = [{
        text: '全部',
        value: undefined
      }]
      if (topMenuId && subCenterMenuId) {
        this.allMenuList && this.allMenuList && this.allMenuList.forEach(topMenu => {
          // 找到平台
          if (topMenu.id === topMenuId) {
            if (topMenu.childMenus) {
              // 处理中心数据
              topMenu.childMenus && topMenu.childMenus.forEach(centerMenu => {
                // 找到选中的中心
                if (centerMenu.id === subCenterMenuId) {
                  // 处理模块名称数据,去除按钮类型脏数据
                  let tempModuleMenuList = centerMenu.childMenus && centerMenu.childMenus.filter(obj => obj.menuType !== 3)
                  tempModuleMenuList.forEach(moduleMenu => {
                    moduleMenuOps.push({
                      text: moduleMenu.name,
                      value: moduleMenu.id
                    })
                  })
                }
              })
            }
          }
        })
      }
      this.platformMenuIdOps = moduleMenuOps
    },

    // 获取菜单页面名称ops
    getViewMenuData () {
      let topMenuId = this.searchParams.topMenuId
      let subCenterMenuId = this.searchParams.subCenterMenuId
      let moduleMenuId = this.searchParams.platformMenuId

      let viewMenuOps = [{
        text: '全部',
        value: undefined
      }]
      if (topMenuId && subCenterMenuId && moduleMenuId) {
        this.allMenuList && this.allMenuList && this.allMenuList.forEach(topMenu => {
          // 找到平台
          if (topMenu.id === topMenuId) {
            if (topMenu.childMenus) {
              // 处理中心数据
              topMenu.childMenus && topMenu.childMenus.forEach(centerMenu => {
                // 找到选中的中心
                if (centerMenu.id === subCenterMenuId) {
                  // 处理模块名称数据,去除【按钮类型】数据
                  let tempModuleMenuList = centerMenu.childMenus && centerMenu.childMenus.filter(obj => obj.menuType !== 3)
                  tempModuleMenuList && tempModuleMenuList.forEach(moduleMenu => {
                    // 找到选中的模块
                    if (moduleMenu.id === moduleMenuId) {
                      // 去除【按钮类型】数据
                      let tempViewMenuList = moduleMenu.childMenus && moduleMenu.childMenus.filter(obj => obj.menuType !== 3)
                      tempViewMenuList && tempViewMenuList.forEach(viewMenu => {
                        // 这里剩下页面和文件夹
                        if (viewMenu.menuType === 2) {
                          // 页面
                          viewMenuOps.push({
                            text: viewMenu.name,
                            value: viewMenu.id
                          })
                        } else {
                          // 文件夹,就再循环一次,只取页面类型
                          let tempMenuList = viewMenu.childMenus && viewMenu.childMenus.filter(obj => obj.menuType === 2)
                          tempMenuList && tempMenuList.forEach(menu => {
                            viewMenuOps.push({
                              text: menu.name,
                              value: menu.id
                            })
                          })
                        }
                      })
                    }
                  })
                }
              })
            }
          }
        })
      }
      this.childMenuIdOps = viewMenuOps
    }
  }
}
</script>
<style lang="scss" scoped>
.DataExportItem-wrapper {
    height: 100%;

    .downLoadBtn {
      color: red;
    }
}

</style>
